<template>
  <div class="min-w-2400">
    <h2>{{ title }}</h2>
    <div class="w-full d-flex margin-t-2">
      <v-spacer />
      <CRButton v-if="!isCurrent" color="primary" @click="goTo">View</CRButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    knownAddress: { type: Object, required: true },
    isCurrent: { type: Boolean, default: false },
  },
  computed: {
    title() {
      return this.knownAddress?.title || ''
    },
  },
  methods: {
    goTo() {
      this.$router.push({
        name: 'known-address.view',
        params: { id: this.knownAddress.knownAddressId },
      })
    },
  },
}
</script>
