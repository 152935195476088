<template>
  <Sheet>
    <span
      v-for="(item, itemIndex) in parents"
      :key="`hierachy-item-${item.knownAddressId}-${itemIndex}-${parents.length}`"
      class="text-gray-medium-dark"
    >
      <router-link
        :to="{
          name: 'known-address.view',
          params: { id: item.knownAddressId },
        }"
        style="text-decoration: none"
        class="text-gray-medium-dark hover:text-primary transition-all transition-duration-300"
      >
        {{ item.title }}
      </router-link>
      <template v-if="itemIndex < parents.length - 1">></template>
    </span>
    <div class="d-flex align-center margin-b-4 w-full">
      <h1 style="margin-bottom: 0">{{ header }}</h1>
      <v-spacer />
      <CRButton
        v-if="isModeView"
        color="primary"
        class="margin-r-2 max-w-140"
        @click="goToAddChild"
      >
        Add Sub-Address
      </CRButton>
      <CRButton
        v-if="isModeView"
        color="primary"
        class="max-w-80"
        @click="edit"
      >
        Edit
      </CRButton>
      <CRButton
        v-if="isModeEdit"
        color="error"
        class="margin-r-2 max-w-100"
        @click="deleteKnownAddress"
      >
        Delete
      </CRButton>
      <CRButton
        v-if="!isModeView"
        color="error"
        class="margin-r-2 max-w-80"
        outline
        @click="cancel"
      >
        Cancel
      </CRButton>
      <CRButton
        v-if="!isModeView"
        color="primary"
        class="max-w-80"
        :disabled="isEditingCoordinates"
        @click="save"
      >
        Save
      </CRButton>
    </div>
    <v-layout row wrap class="margin-a-n3">
      <v-flex shrink>
        <KnownAddressDetailForm ref="knownAddressForm" class="padding-a-3" />
      </v-flex>
      <v-flex grow class="padding-a-3 min-w-300">
        <KnownAddressDetailMap />
      </v-flex>
    </v-layout>
  </Sheet>
</template>

<script>
import Sheet from '@/layouts/Sheet.vue'
import KnownAddressDetailForm from '@/components/KnownAddressDetailForm.vue'
import KnownAddressDetailMap from '@/components/KnownAddressDetailMap.vue'
import knownAddress from '@/services/knownAddress'
import { deepClone } from '@/utils/deepClone'

export default {
  name: 'KnownAddressDetail',
  components: {
    Sheet,
    KnownAddressDetailForm,
    KnownAddressDetailMap,
  },
  metaInfo() {
    return {
      title: `Known Address ${this.knownAddressId}`,
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    knownAddressId() {
      return this.knownAddress?.knownAddressId || ''
    },
    header() {
      const title = this.knownAddress?.title || ''
      if (this.isModeEdit) {
        return `Edit ${title}`
      }
      return title || 'Known Address'
    },
    knownAddress() {
      return this.$store.getters['knownAddressDetail/knownAddress']
    },
    mode() {
      return this.$store.getters['knownAddressDetail/mode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isEditingCoordinates() {
      return this.$store.getters['knownAddressDetail/isEditingCoordinates']
    },
    routeKnownAddressId() {
      return this.$route?.params?.id
    },
    parents() {
      const hierarchy = this.$store.getters['knownAddressDetail/hierarchy']
      if (!hierarchy) {
        return []
      }
      if (this.isModeAdd) {
        return hierarchy
      }
      return hierarchy.slice(0, hierarchy.length - 1)
    },
  },
  watch: {
    routeKnownAddressId: {
      async handler() {
        this.loadKnownAddress()
      },
      immediate: true,
    },
    '$route.name': {
      async handler() {
        const mode = this.$route.name.split('.')[1]
        await this.loadKnownAddress()
        this.$store.dispatch('knownAddressDetail/setMode', mode)
        if (mode === 'add') {
          if (this.$route?.query?.parentKnownAddressId) {
            this.initAddChild()
          } else {
            this.initAddParent()
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    cancel() {
      if (this.isModeEdit) {
        this.$router.push({
          name: 'known-address.view',
          params: { id: this.routeKnownAddressId },
        })
        return
      }
      const parentKnownAddressId = this.$route?.query?.parentKnownAddressId
      if (this.isModeAdd && parentKnownAddressId) {
        this.$router.push({
          name: 'known-address.view',
          params: { id: this.$route?.query?.parentKnownAddressId },
        })
        return
      }
      this.$router.push({ name: 'known-addresses' })
    },
    edit() {
      this.$router.push({
        name: 'known-address.edit',
        params: { id: this.routeKnownAddressId },
      })
    },
    async loadKnownAddress() {
      this.loading = true
      if (this.routeKnownAddressId) {
        const knownAddressResponse = await knownAddress.byId(
          this.routeKnownAddressId
        )
        this.$store.dispatch(
          'knownAddressDetail/setKnownAddress',
          knownAddressResponse.data.data
        )
      }
      const lowestKnownAddressId =
        this.routeKnownAddressId || this.$route?.query?.parentKnownAddressId
      if (lowestKnownAddressId) {
        this.$store.dispatch(
          'knownAddressDetail/fetchTopLevelParent',
          lowestKnownAddressId
        )
      }
      this.loading = false
    },
    async save() {
      const isValid = this.$refs.knownAddressForm.validate()
      if (!isValid) {
        return
      }
      try {
        const result = await this.$store.dispatch('knownAddressDetail/save')
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Known address saved successfully.`,
        })
        this.$router.push({
          name: 'known-address.view',
          params: { id: result.data.data.knownAddressId },
        })
      } catch (error) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: `Error saving known address.`,
        })
        return
      }
      this.$store.dispatch('knownAddressDetail/setMode', 'view')
    },
    goToAddChild() {
      const parentKnownAddressId = deepClone(this.knownAddress)?.knownAddressId
      this.$router.push({
        name: 'known-address.add',
        query: { parentKnownAddressId },
      })
    },
    async initAddChild() {
      const parentKnownAddressId = parseInt(
        this.$route?.query?.parentKnownAddressId
      )
      await this.$store.dispatch(
        'knownAddressDetail/fetchTopLevelParent',
        parentKnownAddressId
      )
      const hierarchy = this.$store.getters['knownAddressDetail/hierarchy']
      const parentKnownAddress = hierarchy.find(
        (knownAddress) => knownAddress.knownAddressId === parentKnownAddressId
      )

      const newKnownAddress = deepClone(parentKnownAddress)
      newKnownAddress.knownAddressId = null
      newKnownAddress.title = ''
      newKnownAddress.parentKnownAddressId = parentKnownAddressId
      delete newKnownAddress.childKnownAddresses
      // push parent to the hierarchy
      const newHierarchy = deepClone(
        this.$store.getters['knownAddressDetail/hierarchy']
      )
      newHierarchy.push(this.knownAddress)
      this.$store.dispatch('knownAddressDetail/setHierarchy', newHierarchy)

      this.$store.dispatch(
        'knownAddressDetail/setKnownAddress',
        newKnownAddress
      )
      this.$store.dispatch('knownAddressDetail/setMode', 'add')
    },
    initAddParent() {
      this.$store.dispatch('knownAddressDetail/initNewKnownAddress')
      this.showAddressSearch = true
    },
    async deleteKnownAddress() {
      const parentKnownAddressId = this.knownAddress?.parentKnownAddressId
      await knownAddress.delete(this.knownAddressId)
      if (parentKnownAddressId) {
        this.$router.push({
          name: 'known-address.view',
          params: { id: parentKnownAddressId },
        })
      } else {
        this.$router.push({ name: 'known-addresses' })
      }
    },
  },
}
</script>
