<template>
  <v-form ref="form">
    <CRAddressSelector
      v-if="isModeAdd && !parentKnownAddressId"
      placeholder="Type to search"
      label="Search for Address"
      @place-selected="selectBaseAddress"
    />
    <div class="d-flex align-center margin-t-2">
      <CRInput
        :value="knownAddress.lat"
        label="Latitude"
        placeholder="Latitude"
        :disabled="isModeView || !isEditingCoordinates"
        :hide-details="hideDetails"
        class="margin-r-1"
        :rules="[(val) => !!val || 'Latitude is Required']"
        @input="$store.dispatch('knownAddressDetail/setLatitude', $event)"
      />
      <CRInput
        :value="knownAddress.lng"
        label="Longitude"
        placeholder="Longitude"
        :disabled="isModeView || !isEditingCoordinates"
        :hide-details="hideDetails"
        class="margin-l-1"
        :rules="[(val) => !!val || 'Longitude is Required']"
        @input="$store.dispatch('knownAddressDetail/setLongitude', $event)"
      />
    </div>
    <CRInput
      :value="knownAddress.title"
      label="Title"
      placeholder="Title"
      :disabled="isModeView"
      :hide-details="hideDetails"
      :rules="[(val) => !!val || 'Title is Required']"
      @input="$store.dispatch('knownAddressDetail/setTitle', $event)"
    />
    <CRInput
      :value="knownAddress.street1"
      label="Street 1"
      placeholder="Street 1"
      :disabled="isModeView"
      :hide-details="hideDetails"
      @input="$store.dispatch('knownAddressDetail/setStreet1', $event)"
    />
    <CRInput
      :value="knownAddress.street2"
      label="Street 2"
      placeholder="Street 2"
      :disabled="isModeView"
      :hide-details="hideDetails"
      @input="$store.dispatch('knownAddressDetail/setStreet2', $event)"
    />
    <CRInput
      :value="knownAddress.city"
      label="City"
      placeholder="City"
      :disabled="isModeView"
      :hide-details="hideDetails"
      @input="$store.dispatch('knownAddressDetail/setCity', $event)"
    />
    <div class="d-flex align-center margin-t-2">
      <CRSelect
        :value="knownAddress.state"
        :items="states"
        item-text="label"
        item-value="value"
        label="State"
        placeholder="State"
        :disabled="isModeView"
        class="margin-r-1"
        :hide-details="hideDetails"
        @input="$store.dispatch('knownAddressDetail/setState', $event)"
      />
      <CRInput
        :value="knownAddress.postalCode"
        label="Zip Code"
        placeholder="Zip Code"
        :disabled="isModeView"
        class="margin-l-1 w-120"
        :hide-details="hideDetails"
        @input="$store.dispatch('knownAddressDetail/setPostalCode', $event)"
      />
    </div>
  </v-form>
</template>

<script>
import { getStates } from '@/utils/states'
import { buildAddressTypes } from '@/utils/address'

export default {
  name: 'KnownAddressDetailForm',
  data() {
    return {
      hideDetails: true,
    }
  },
  computed: {
    states() {
      return getStates()
    },
    isModeView() {
      return this.$store.getters['knownAddressDetail/mode'] === 'view'
    },
    isModeEdit() {
      return this.$store.getters['knownAddressDetail/mode'] === 'edit'
    },
    isModeAdd() {
      return this.$store.getters['knownAddressDetail/mode'] === 'add'
    },
    isEditingCoordinates() {
      return this.$store.getters['knownAddressDetail/isEditingCoordinates']
    },
    knownAddress() {
      return this.$store.getters['knownAddressDetail/knownAddress']
    },
    parentKnownAddressId() {
      return this.knownAddress?.parentKnownAddressId || null
    },
  },
  methods: {
    validate() {
      this.hideDetails = true
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        this.hideDetails = false
      }
      return isValid
    },
    selectBaseAddress(place) {
      const address = place.place
      const title = place.defaultAddressTitle || null
      this.$store.dispatch('knownAddressDetail/setLatitude', address.lat)
      this.$store.dispatch('knownAddressDetail/setLongitude', address.lng)
      this.$store.dispatch('knownAddressDetail/setStreet1', address.street1)
      this.$store.dispatch('knownAddressDetail/setStreet2', address.street2)
      this.$store.dispatch('knownAddressDetail/setCity', address.city)
      this.$store.dispatch('knownAddressDetail/setState', address.state)
      this.$store.dispatch(
        'knownAddressDetail/setPostalCode',
        address.postalCode
      )
      this.$store.dispatch('knownAddressDetail/setCountry', address.country)
      this.$store.dispatch('knownAddressDetail/setTitle', title)
      this.$store.dispatch('knownAddressDetail/setTimeZone', address.timeZone)
      this.$store.dispatch('knownAddressDetail/setName', address.addressName)
      const addressTypes = buildAddressTypes(address.types)
      this.$store.dispatch('knownAddressDetail/setAddressTypes', addressTypes)
    },
  },
}
</script>
