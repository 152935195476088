<template>
  <div class="position-relative h-full">
    <template v-if="!isModeView && !loading">
      <div
        v-if="!isEditingCoordinates"
        key="edit"
        class="position-absolute top-12 right-12 w-32 h-32 background-primary z-5 align-center cursor-pointer"
        @click="
          $store.dispatch('knownAddressDetail/setIsEditingCoordinates', true)
        "
      >
        <CRIcon
          view-box="0 0 24 24"
          class="margin-l-1 margin-t-1"
          color="white"
        >
          edit
        </CRIcon>
      </div>
      <template v-else>
        <div
          key="check"
          class="position-absolute top-12 right-12 w-32 h-32 background-success z-5 align-center cursor-pointer"
          @click="confirmCoordinates"
        >
          <CRIcon
            view-box="0 0 24 24"
            class="margin-l-1 margin-t-1"
            color="white"
          >
            check
          </CRIcon>
        </div>
        <div
          key="close"
          class="position-absolute top-12 right-48 w-32 h-32 background-error z-5 align-center cursor-pointer"
          @click="revertCoordinates"
        >
          <CRIcon
            view-box="0 0 24 24"
            class="margin-l-1 margin-t-1"
            color="white"
          >
            close
          </CRIcon>
        </div>
      </template>
    </template>
    <GmapMap
      ref="gMap"
      class="min-h-300 h-full w-full"
      :center="mapCenter"
      :options="options"
      :zoom="defaultZoom"
      map-type-id="roadmap"
    >
      <template v-if="!loading && google">
        <GmapMarker
          :position="markerPosition"
          :options="markerOptions"
          :draggable="isEditingCoordinates"
          @dragend="handleMarkerDragEnd"
          @click="openInfoWindowKnownAddressId = knownAddress.knownAddressId"
        >
          <GmapInfoWindow
            :opened="
              knownAddress &&
              openInfoWindowKnownAddressId === knownAddress.knownAddressId
            "
            @closeclick="openInfoWindowKnownAddressId = null"
          >
            <KnownAddressDetailMapInfoWindow
              :known-address="knownAddress"
              is-current
            />
          </GmapInfoWindow>
        </GmapMarker>
        <GmapMarker
          v-for="(child, childIndex) in children"
          :key="`child-marker-${child.knownAddressId}-${childIndex}`"
          :position="{ lat: child.lat, lng: child.lng }"
          :options="childMarkerOptions"
          :opacity="0.5"
          @click="openInfoWindowKnownAddressId = child.knownAddressId"
        >
          <GmapInfoWindow
            :opened="openInfoWindowKnownAddressId === child.knownAddressId"
            @closeclick="openInfoWindowKnownAddressId = null"
          >
            <KnownAddressDetailMapInfoWindow :known-address="child" />
          </GmapInfoWindow>
        </GmapMarker>
      </template>
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { mapStyles } from './mapStyles.js'
import KnownAddressDetailMapInfoWindow from '@/components/KnownAddressDetailMapInfoWindow.vue'

export default {
  name: 'KnownAddressDetailMap',
  components: {
    KnownAddressDetailMapInfoWindow,
  },
  data() {
    return {
      map: null,
      mapCenter: { lat: 40.7703066, lng: -111.8920475 },
      markerPosition: { lat: 0, lng: 0 },
      defaultZoom: 15,
      hideDetails: true,
      options: {
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
        styles: mapStyles,
      },
      openInfoWindowKnownAddressId: null,
    }
  },
  computed: {
    google: gmapApi,
    mode() {
      return this.$store.getters['knownAddressDetail/mode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    isEditingCoordinates() {
      return this.$store.getters['knownAddressDetail/isEditingCoordinates']
    },
    markerOptions() {
      if (this.loading) {
        return {}
      }
      const pinColor = this.isEditingCoordinates
        ? this.$cr.theme.primary
        : this.$cr.theme.black
      const pinSVG = `data:image/svg+xml;charset=UTF-8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="${encodeURIComponent(
          pinColor
        )}" stroke="none">
          <path d="M20,0C11.8,0,5.1,6.7,5.1,14.9c0,12.3,13.4,24.2,14,24.7c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.7-0.1,0.9-0.3
            c0.6-0.5,14-12.5,14-24.7C34.9,6.7,28.2,0,20,0z M20,19.1c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1
	          C24.1,17.2,22.3,19.1,20,19.1z"/>
        </svg>`

      return {
        icon: {
          url: pinSVG,
          scaledSize: new google.maps.Size(32, 32),
        },
      }
    },
    childMarkerOptions() {
      if (this.loading) {
        return {}
      }
      const pinColor = this.$cr.theme.black
      const pinSVG = `data:image/svg+xml;charset=UTF-8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="${encodeURIComponent(
          pinColor
        )}" stroke="none">
          <path d="M20,0C11.8,0,5.1,6.7,5.1,14.9c0,12.3,13.4,24.2,14,24.7c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.7-0.1,0.9-0.3
            c0.6-0.5,14-12.5,14-24.7C34.9,6.7,28.2,0,20,0z M20,19.1c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1
	          C24.1,17.2,22.3,19.1,20,19.1z"/>
        </svg>`

      return {
        icon: {
          url: pinSVG,
          scaledSize: new google.maps.Size(24, 24),
        },
      }
    },
    knownAddress() {
      return this.$store.getters['knownAddressDetail/knownAddress']
    },
    children() {
      return this.knownAddress?.childKnownAddresses || []
    },
  },
  watch: {
    'knownAddress.lat': {
      immediate: true,
      handler() {
        if (!this.knownAddress?.lat) {
          return
        }
        this.markerPosition.lat = parseFloat(this.knownAddress.lat)
        this.centerMapOnLatLng()
      },
    },
    'knownAddress.lng': {
      immediate: true,
      handler() {
        if (!this.knownAddress?.lng) {
          return
        }
        this.markerPosition.lng = parseFloat(this.knownAddress.lng)
        this.centerMapOnLatLng()
      },
    },
    mode() {
      this.openInfoWindowKnownAddressId = null
    },
  },
  mounted() {
    this.loadMap()
  },
  methods: {
    async loadMap() {
      if (this.$refs.gMap == null) {
        return
      }

      this.map = await this.$refs.gMap.$mapPromise
      this.loading = false
    },
    handleMarkerDragEnd(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
    },
    confirmCoordinates() {
      this.$store.dispatch('knownAddressDetail/setIsEditingCoordinates', false)
      this.$store.dispatch(
        'knownAddressDetail/setLatitude',
        this.markerPosition.lat
      )
      this.$store.dispatch(
        'knownAddressDetail/setLongitude',
        this.markerPosition.lng
      )
    },
    centerMapOnLatLng() {
      this.mapCenter.lat = parseFloat(this.knownAddress.lat)
      this.mapCenter.lng = parseFloat(this.knownAddress.lng)
    },
    revertCoordinates() {
      this.$store.dispatch('knownAddressDetail/setIsEditingCoordinates', false)
      this.markerPosition.lat = parseFloat(this.knownAddress.lat)
      this.markerPosition.lng = parseFloat(this.knownAddress.lng)
    },
  },
}
</script>
